import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Action } from 'src/app/core/models/action.model';
import { UserService } from 'src/app/core/services/user.service';
import { DocumentKycUploadModalComponent } from 'src/app/shared/components/simple/document-kyc-upload-modal/document-kyc-upload-modal.component';
import { DocumentUploadModalComponent } from 'src/app/shared/components/simple/document-upload-modal/document-upload-modal.component';

@Component({
  selector: 'app-oa-kyc',
  templateUrl: './oa-kyc.component.html',
  styleUrls: ['./oa-kyc.component.scss', '../oa-styles.scss'],
})
export class OaKycComponent {
  @Input() action!: Action;
  @Output() changed = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog, private us: UserService) {
    // this.uploadDialog();
  }

  ngOnInit() {
    // console.log('Action', this.action);
    // console.log('Action', this.action.description);
    // console.log('Action', this.action.description?.includes('depends on'));
  }

  async uploadDialog() {
    const uid = (await this.us.getCurrentUser())?.id;

    if (!this.action) {
      throw new Error('No requirement loaded');
    }

    if (!uid) {
      throw new Error('No user logged in');
    }

    // @TODO: Improve and make dynamic

    console.log('ACTION', this.action);
    const dialogData: {
      bucket: string;
      name: string;
      type: 'PERSONAL' | 'CLAIM';
      multiple: boolean;
      ref: string;
      provider: string;
    } = {
      bucket: 'client-documents',
      name: 'kyc.png',
      multiple: false,
      ref: uid,
      type: 'PERSONAL',
      provider: this.action.config.provider,
    };

    const dialogRef = this.dialog.open(DocumentKycUploadModalComponent, {
      data: dialogData,
      height: '50vh',
      minHeight: '500px',
      maxHeight: '700px',
      width: '90vw',
      maxWidth: '700px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.changed.emit(true);
    });
  }
  checkDependsOn() {
    return false;
    // this.action.description?.includes('depends on');
  }
}
