import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Action } from 'src/app/core/models/action.model';
import { ClaimsService } from 'src/app/core/services/claims.service';

@Component({
  selector: 'app-outstanding-actions',
  templateUrl: './outstanding-actions.component.html',
  styleUrls: [
    './outstanding-actions.component.scss',
    './components/oa-styles.scss',
  ],
})
export class OutstandingActionsComponent implements OnInit {
  @Input() claimId!: string;
  @Output() outstandingActions = new EventEmitter<Action[]>();

  loading: boolean = true;
  error: string = '';
  actions?: Action[] | null;

  constructor(private cs: ClaimsService) {}
  async ngOnInit() {
    await this.loadActions();
  }

  async loadActions() {
    this.loading = true;
    const a = await this.cs.getRequirementsListForClaimV2(this.claimId);
    console.log('loadActions', a);

    this.loading = false;
    if (!a) {
      this.error = 'Could not load outstanding actions. Try again later.';
      return;
    }
    this.actions = a;
    console.log('CPKCAIO1', this.actions);

    this.outstandingActions.emit(this.actions);
  }

  async onChange() {
    await this.loadActions();
  }
}
