import { UtilsService } from './../../../../core/services/utils.service';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  finalize,
  Observable,
  of,
  Subject,
  Subscription,
  tap,
} from 'rxjs';
import { Base } from 'src/app/core/models/base.model';

import {
  QueryResult,
  Sort,
  DatabaseService,
  Filter,
} from 'src/app/core/services/base/database.service';
import { DynamicService } from 'src/app/core/services/base/dynamic.service';
import { AccordeonHostDirective } from './directives/accordeon-host.directive';

export type SelectionType = 'none' | 'all' | 'none-some' | 'all-some';
interface Selection {
  type: SelectionType;
  selected: any[];
  unselected: any[];
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(AccordeonHostDirective, { static: false })
  accHost!: AccordeonHostDirective;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  selection: Selection = {
    type: 'none',
    selected: [],
    unselected: [],
  };
  @Input('settings') settings!: TableSettings;
  @Output() event = new EventEmitter<any>();

  editedFilter: number = 0;
  tempFilter!: Filter;
  settings$!: BehaviorSubject<TableSettings>;
  currentSettings!: TableSettings;

  searchval?: string;

  displayedColumns: string[] = [];
  dataSource?: SBDataSource<any>;
  subscriptions: Subscription[] = [];

  parentColumnSettings: { [id: string]: Column[] } = {};

  columnFilters: { [colname: string]: Filter[] } = {};

  // filteringColumnName?: string;
  filteringColumn?: Column | null;

  expandedElement: any;

  /**
   * @description Subject for search input, debounced by 300ms.
   *  This is used to avoid multiple requests to the server.
   *  The searchChange$ emits the value of the search input and emit this value with your config to the settings$ to request the new list of data.
   */
  private searchChange$ = new Subject<string>();

  constructor(private sbs: DynamicService<Base>, private utils: UtilsService) {}

  objectKeys = Object.keys;

  ngOnInit(): void {
    this.settings$ = new BehaviorSubject({ ...this.settings });
    this.sbs.load(this.settings.entity);

    this.subscriptions.push(
      this.settings$.subscribe((s) => {
        console.log('Table -> Settings$ subscription', s);
        this.currentSettings = s;
      })
    );

    // Handle displayed columns
    this.subscriptions.push(
      this.settings$.subscribe((s) => {
        // console.log('Table -> Settings$ subscription -> displayed columns', s);
        this.displayedColumns = [
          'select',
          ...s.columns.filter((c) => c.visible).map((c) => c.id),
        ];
      })
    );

    // Handle nested columns
    this.subscriptions.push(
      this.settings$.subscribe((s) => {
        console.log('Table -> Settings$ subscription -> nested columns', s);
        const withParent = s.columns.filter((c) => !!c.parent);
        const colStructure: { [id: string]: Column[] } = {};
        withParent.forEach((c) => {
          const parent = c.parent!;
          if (!colStructure[parent]) {
            colStructure[parent] = [];
          }
          colStructure[parent].push(c);
        });

        // console.log(
        //   'Table -> Settings$ subscription -> nested columns -> colStructure',
        //   colStructure
        // );
        this.parentColumnSettings = colStructure;
      })
    );

    this.subscriptions.push(
      this.searchChange$
        .pipe(
          tap((e) => console.log('Search Change')),
          debounceTime(500),
          tap((searchValue) => {
            if (!this.currentSettings.textFilterConfig) {
              return;
            }

            const newSettings = { ...this.currentSettings };
            newSettings.textFilterConfig = {
              column: newSettings.textFilterConfig?.column || 'id',
              value: searchValue,
            };
            this.settings$.next(newSettings);
          })
        )
        .subscribe()
    );

    this.dataSource = new SBDataSource<Base>(this.sbs, this.settings$);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit() {
    console.log('After view init');

    const pageSub = this.paginator.page
      .pipe(
        tap((c) => {
          const newSettings: TableSettings = {
            ...this.currentSettings,
            pageIndex: c.pageIndex,
            pageSize: c.pageSize,
          };
          this.settings$.next(newSettings);
        })
      )
      .subscribe();

    this.subscriptions.push(pageSub);
  }

  openFilter(col: Column) {
    setTimeout(() => {
      this.filteringColumn = col;
    }, 300);
  }

  toggleVisibility(event: any, colIdx: number) {
    const newSettings = { ...this.currentSettings };
    newSettings.columns[colIdx].visible = !newSettings.columns[colIdx].visible;
    this.settings$.next(newSettings);
  }

  toggleVisibilityById(event: any, colid: string) {
    const newSettings = { ...this.currentSettings };
    const colIdx = this.settings.columns.findIndex((c) => c.id == colid);
    newSettings.columns[colIdx].visible = !newSettings.columns[colIdx].visible;
    this.settings$.next(newSettings);
  }

  accordeonButtonClick(event: any, element: any) {
    this.expandedElement = this.expandedElement === element ? null : element;
    event.stopPropagation();
    if (this.expandedElement) {
      // console.log(element);
      setTimeout(() => {
        // trigger change detection
        this.loadAccordeonComponent(element);
      }, 50);
    }
  }

  loadAccordeonComponent(data: any) {
    const viewContainerRef = this.accHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<any>(
      this.currentSettings.accordeonComponent
    );
    componentRef.instance.data = data;
  }

  runAction(act: Action) {
    act.run(this);
  }

  /** Selection context */
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    const atualType = this.selection.type;
    console.log('Toggle all rows with status:', atualType);
    if (atualType !== 'all') {
      this.selection = {
        type: 'all',
        selected: [],
        unselected: [],
      };
    } else {
      this.selection = {
        type: 'none',
        selected: [],
        unselected: [],
      };
    }

    // console.log('Selection after changes:', this.selection);
  }
  toggleOneRow(row: any) {
    const atualType = this.selection.type;
    switch (atualType) {
      case 'all': {
        const idx = this.selection.unselected.findIndex(
          (s) => Object.keys(this.utils.getDeepDifferences(s, row)).length === 0
        );
        this.selection = {
          type: 'all-some',
          selected: [],
          unselected:
            idx !== -1
              ? this.selection.unselected.filter(
                  (s) =>
                    Object.keys(this.utils.getDeepDifferences(s, row)).length >
                    0
                )
              : [...this.selection.unselected, row],
        };
        break;
      }
      case 'all-some': {
        const idx = this.selection.unselected.findIndex(
          (s) => Object.keys(this.utils.getDeepDifferences(s, row)).length === 0
        );

        this.selection = {
          type: 'all-some',
          selected: [],
          unselected:
            idx !== -1
              ? this.selection.unselected.filter(
                  (s) =>
                    Object.keys(this.utils.getDeepDifferences(s, row)).length >
                    0
                )
              : [...this.selection.unselected, row],
        };
        break;
      }
      case 'none': {
        const idx = this.selection.selected.findIndex(
          (s) => Object.keys(this.utils.getDeepDifferences(s, row)).length === 0
        );
        this.selection = {
          type: 'none-some',
          selected:
            idx !== -1
              ? this.selection.selected.filter(
                  (s) =>
                    Object.keys(this.utils.getDeepDifferences(s, row)).length >
                    0
                )
              : [...this.selection.selected, row],
          unselected: [],
        };
        break;
      }
      case 'none-some': {
        const idx = this.selection.selected.findIndex(
          (s) => Object.keys(this.utils.getDeepDifferences(s, row)).length === 0
        );
        this.selection = {
          type: 'none-some',
          selected:
            idx !== -1
              ? this.selection.selected.filter(
                  (s) =>
                    Object.keys(this.utils.getDeepDifferences(s, row)).length >
                    0
                )
              : [...this.selection.selected, row],
          unselected: [],
        };
        break;
      }
      default: {
        this.selection = {
          type: 'none',
          selected: [],
          unselected: [],
        };
      }
    }

    if (
      this.selection.selected.length === 0 &&
      this.selection.unselected.length === 0
    ) {
      this.selection = {
        type:
          this.selection.type === 'all-some'
            ? 'all'
            : this.selection.type === 'none-some'
            ? 'none'
            : 'none',
        selected: [],
        unselected: [],
      };
    }
    // console.log('Selection after changes:', this.selection);
  }

  /** The label for the checkbox on the passed row */
  isSelected(row: any): boolean {
    switch (this.selection.type) {
      case 'all':
        return true;
      case 'none':
        return false;
      case 'all-some':
        return (
          this.selection.unselected.findIndex(
            (s) =>
              Object.keys(this.utils.getDeepDifferences(s, row)).length === 0
          ) === -1
        );
      case 'none-some':
        return (
          this.selection.selected.findIndex(
            (s) =>
              Object.keys(this.utils.getDeepDifferences(s, row)).length === 0
          ) !== -1
        );
      default:
        return false;
    }
  }
  checkboxLabel(row?: any): string {
    return `${this.isSelected(row) ? 'deselect' : 'select'} row ${
      row ? row.id : ''
    }`;
  }

  getSelectionLength(): number {
    if (!this.dataSource?.amount) return 0;

    switch (this.selection.type) {
      case 'all':
        return this.dataSource.amount;
      case 'none':
        return 0;
      case 'all-some':
        return this.dataSource.amount - this.selection.unselected.length;
      case 'none-some':
        return this.selection.selected.length;
      default:
        // Caso você precise de um valor padrão caso o tipo não corresponda a nenhum dos casos
        return 0;
    }
  }
  /** Selection context */

  columnChange(column: Column) {
    console.log('Detected filters changed', column);
    const newSettings = { ...this.currentSettings };

    console.log('Detected filters changed -> newSettings', newSettings);

    const nci = newSettings.columns.findIndex((c) => c.id == column.id);
    newSettings.columns[nci] = column;
    this.settings$.next(newSettings);
  }

  emit(emitter: any, context: any) {
    this.event.next({ emitter, context });
  }

  handleSearchChange(ev: any) {
    this.searchChange$.next(ev.target.value);
  }
}

export interface Column {
  id: string;
  name: string;
  type:
    | 'text'
    | 'link'
    | 'url'
    | 'events'
    | 'json'
    | 'date'
    | 'number'
    | 'boolean'
    | 'datetime'
    | 'options'
    | 'custom'
    | 'component'
    | 'accordeon'
    | 'multioptions';
  visible: boolean;
  order: number;
  component?: any;
  filters: Filter[];
  entityName?: string;
  entityPath?: string;
  parent?: string;
  sort?: 'asc' | 'desc';
  filterType?: 'text' | 'number' | 'date' | 'boolean' | 'options';
  config?: any;
  linkText?: string;

  // Used to access JSON objects. Root is the name of the column where the JSON is stored. Currently only supports one level.
  root?: string;
}

export class SBDataSource<T extends Base> extends MatTableDataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private settings$: BehaviorSubject<TableSettings>;
  private settings!: TableSettings;
  public loading$ = this.loadingSubject.asObservable();
  public amount: number = 0;

  private subscriptions: Subscription[] = [];

  constructor(
    private sbs: DynamicService<Base>,
    settings$: BehaviorSubject<TableSettings>
  ) {
    super();
    this.settings$ = settings$;
  }

  override connect() {
    // console.log('connecting table', this);

    const settingss = this.settings$.pipe(
      // tap((ev) => console.log('SBDS -> settings$', ev)),
      tap((ev) => (this.settings = ev)),
      tap((ev) => this.sbs.load(ev.entity)),
      tap((ev) => this.query())
    );

    this.subscriptions.push(settingss.subscribe());
    return this.dataSubject;
  }

  override disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private query() {
    this.loadingSubject.next(true);

    const sorts: Sort[] = [];
    this.settings.columns.forEach((e) => {
      if (!e.sort) {
        return null;
      }
      const ret: Sort = {
        column: e.id,
        direction: e.sort,
      };
      sorts.push(ret);
      return ret;
    });

    this.sbs
      .list(
        this.settings.pageIndex,
        this.settings.pageSize,
        getFiltersFromColumns(this.settings.columns),
        sorts,
        this.settings.textFilterConfig?.value
          ? this.settings.textFilterConfig
          : undefined
      )
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((res) => {
        console.log('sbds -> query -> list -> res', res);
        this.dataSubject.next((res as QueryResult<T>).data);
        this.amount = (res as QueryResult<T>).count;
      });
  }
}

export interface TableSettings {
  columns: Column[];
  entity: string;
  search: string;
  pageSize?: number;
  pageIndex?: number;
  accordeonComponent?: any;
  actionSettings?: ActionGroup[];

  /**
   * @description If true, the table is enabled to filter the data by the textFilterConfig value and column.
   */
  textFilterConfig?: TextFilterConfig;
}

export interface TextFilterConfig {
  column: string;
  value: string;
}

export interface Action {
  run: (context: any) => boolean | Promise<boolean>;
  id: string;
  displayName: string;
}

export interface ActionGroup {
  id: string;
  displayName: string;
  actions: Action[];
}

export function getFiltersFromColumns(columns: Column[]): Filter[] {
  return columns.reduce((prev: Filter[], current) => {
    let cf: Filter[] = current.filters;

    if (current.type === 'json') {
      cf = cf.map((f) => {
        return { ...f, column: current.root + '->' + current.id };
      });
    }

    return prev.concat(cf);
  }, []);
}
